<template>
  <div style="width: 100%">
    <!-- ALlegati dialogue -->
    <v-dialog content-class="artDialog" v-model="benestareallegatidialog" width="unset">
      <GestioneAllegato
        ref="componenteAllegati"
        controller_allegati="Global"
        controller_caratteristiche_tabella="Manutenzione"
        :id_riferimento="testaCommune.id_piano_controllo"
        tipo_allegato="PIANO_CONTROLLO"
        @snackbarOpen="snackbarOpen"
      />
    </v-dialog>
    <!-- Snackbar -->
    <v-snackbar
      content-class="snackbar"
      :color="snackbar_background"
      right
      v-model="snackbar"
      :timeout="3000"
    >
      <v-icon :color="snackbar_icon_color" :size="64">{{ snackbar_icon }}</v-icon>
      <h2 :style="{ color: snackbar_text_color + '!important' }" class="ml-16">
        {{ snackbar_text }}
      </h2>
      <v-btn
        @click="snackbar = false"
        fab
        dark
        text
        small
        style="margin-top: -6rem; float: right !important; margin-right: -1rem !important"
      >
        <v-icon :color="snackbar_icon_color">mdi-window-close</v-icon>
      </v-btn>
    </v-snackbar>
    <v-overlay :value="overlay" style="z-index: 10">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-dialog v-model="elementoDialog">
      <div
        style="
          background-color: rgba(33, 33, 33, 0.46);
          bottom: 0;
          height: 100%;
          z-index: 2;
          left: 0;
          position: absolute;
          right: 0;
          top: 0;
          transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
          width: 100%;
        "
        v-if="controllo_qualita_visible"
      >
        <div
          style="
            height: 90%;
            left: 5%;
            position: absolute;
            top: 5%;
            transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
            width: 90%;
          "
        >
          <Controllo
            @click_indietro="controllo_qualita_visible = false"
            :destinazione="'listener_piano'"
            :id_articolo="testaCommune.id_articolo"
            :riferimento="10 + dettaglio_object.id_piano_controllo_dettaglio"
          />
        </div>
      </div>
      <v-card>
        <v-toolbar color="indigo" dense>
          <v-toolbar-title class="white--text" v-if="modifica == false"
            >Aggiungi elemento</v-toolbar-title
          >
          <v-toolbar-title class="white--text" v-else>Modifica elemento</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="controllo_qualita_visible = true"
                v-bind="attrs"
                v-on="on"
                small
                color="#f3f3f3"
                ><v-icon color="primary">mdi-paperclip</v-icon></v-btn
              >
            </template>
            <span>Allega file </span>
          </v-tooltip>
          <Button
            classe_bottone="ml-2"
            colore_icona="success"
            idForm="QUALITA"
            idOggetto="SALVA_PIANOCONTROLLO_ELEMENT"
            mdi_icona="mdi-floppy"
            testo_tooltip="Aggiungi"
            v-on:buttonClick="salvacaratteristicha"
          />
          <v-spacer></v-spacer>
          <v-btn
            light
            small
            @click="(elementoDialog = false), (val_minimo = null), (val_massimo = null)"
          >
            <v-icon color="error"> mdi-close </v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-form ref="form">
            <v-container>
              <v-row>
                <v-col cols="3">
                  <v-autocomplete
                    dense
                    v-model="dettaglio_object.id_caratteristica"
                    :items="caratteristiche"
                    item-text="des"
                    item-value="id_tabella"
                    label="Caratteristiche"
                    placeholder="Caratteristiche"
                  >
                    <template #label>
                      <span class="">Caratteristiche</span>
                      <a @click.stop style="pointer-events: all">
                        <Modules
                          v-on:dialogModules="getTabelleAutocontrollo"
                          tipo="CARATTERISTICHE"
                          nome="Caratteristiche"
                          setaction="settabelle"
                          getaction="gettabelle"
                          controller="Magazzino"
                          filter="validita = 1"
                          :dati="[{ des: '' }]"
                        />
                      </a>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="3">
                  <v-autocomplete
                    dense
                    v-model="dettaglio_object.id_fmea_modo_guasto"
                    :items="modi_di_guasto"
                    item-text="des"
                    item-value="id_tabella"
                    label="Guasto"
                    placeholder="Guasto"
                  >
                    <template #label>
                      <span class="">Guasto</span>
                      <a @click.stop style="pointer-events: all">
                        <Modules
                          v-on:dialogModules="getTabelleAutocontrollo"
                          tipo="MODO_DI_GUASTO"
                          nome="Modo di Guasto"
                          setaction="settabelle"
                          getaction="gettabelle"
                          controller="Magazzino"
                          filter="validita = 1"
                          :dati="[{ des: '' }]"
                        />
                      </a>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="3">
                  <v-autocomplete
                    dense
                    v-model="dettaglio_object.id_tipo_controllo"
                    :items="tipocontrollo"
                    item-text="des"
                    item-value="id_tabella"
                    label="Tipo controllo"
                    placeholder="Tipo controllo"
                  >
                    <template #label>
                      <span class="">Tipo controllo</span>
                      <a @click.stop style="pointer-events: all">
                        <Modules
                          v-on:dialogModules="getTabelleAutocontrollo"
                          tipo="TIPOCONTROLLO"
                          nome="Tipo controllo"
                          setaction="settabelle"
                          getaction="gettabelle"
                          controller="Magazzino"
                          filter="validita = 1"
                          :dati="[{ des: '' }]"
                        />
                      </a>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="3">
                  <v-autocomplete
                    dense
                    v-model="dettaglio_object.id_difetto"
                    :items="difetto"
                    item-text="des"
                    item-value="id_tabella"
                    placeholder="Difetto"
                    label="Difetto"
                  >
                    <template #label>
                      <span class="">Difetto</span>
                      <a @click.stop style="pointer-events: all">
                        <Modules
                          v-on:dialogModules="getTabelleAutocontrollo"
                          tipo="DIFETTO"
                          nome="Difetto"
                          setaction="settabelle"
                          getaction="gettabelle"
                          controller="Magazzino"
                          filter="validita = 1"
                          :dati="[{ des: '' }]"
                        />
                      </a>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="3" class="mt-4">
                  <v-autocomplete
                    dense
                    v-model="dettaglio_object.id_um"
                    :items="unitamisura"
                    item-text="des"
                    item-value="id_tabella"
                    placeholder="Unita misura"
                    label="Unita misura"
                  >
                    <!-- <template #label>
                    <span class="">Unita misura</span>
                    <a @click.stop style="pointer-events: all">
                      <Modules
                        v-on:dialogModules="getTabelleAutocontrollo"
                        tipo="UNITA_MISURA"
                        nome="Unita misura"
                        setaction="settabelle"
                        getaction="gettabelle"
                        controller="Magazzino"
                        filter="validita = 1"
                        :dati="[{ des: '' }]"
                      />
                    </a>
                  </template> -->
                  </v-autocomplete>
                </v-col>
                <v-col cols="3">
                  <TextField
                    idForm="PIANOCONTROLLO"
                    idOggetto="PIANOCONTROLLO_VAL_MIN"
                    label_input="Val minimo"
                    palceholder_input="Val minimo"
                    :vmodel="val_minimo"
                    v-on:changeText="(val) => (this.val_minimo = val)"
                    :min_input="0"
                    type_input="number"
                  />
                </v-col>
                <v-col cols="3">
                  <TextField
                    idForm="PIANOCONTROLLO"
                    idOggetto="PIANOCONTROLLO_VAL_MAX"
                    label_input="Val massimo"
                    palceholder_input="Val massimo"
                    ref="val_massimo"
                    :vmodel="val_massimo"
                    :rules_input="[rules.min]"
                    v-on:changeText="(val) => (this.val_massimo = val)"
                    :min_input="0"
                    type_input="number"
                  />
                </v-col>
                <v-col cols="3">
                  <TextField
                    idForm="PIANOCONTROLLO"
                    idOggetto="PIANOCONTROLLO_VAL_NOMINALE"
                    label_input="Val nominale"
                    palceholder_input="Val nominale"
                    :vmodel="val_nominale"
                    v-on:changeText="(val) => (this.val_nominale = val)"
                    :min_input="0"
                    type_input="number"
                  />
                </v-col>
                <v-col cols="7">
                  <v-textarea
                    v-model="note"
                    outlined
                    placeholder="Note"
                    label="Note"
                    :rows="2"
                  >
                  </v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <!-- <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn v-if="modifica == false" @click="confirmAddRecord()">
              <v-icon color="success">mdi-plus</v-icon>
            </v-btn>
            <v-btn v-else @click="confirmEditRecord()">
              <v-icon color="success">mdi-content-save-outline</v-icon>
            </v-btn>
          </v-card-actions> -->
      </v-card>
    </v-dialog>
    <v-toolbar color="indigo" dense>
      <v-toolbar-title class="white--text">Piano Controllo Dettaglio</v-toolbar-title>
      <v-col cols="2"></v-col>
      <v-col cols="6">
        <v-btn light small style="margin-right: 5px">
          <v-icon color="primary">mdi-history</v-icon>
        </v-btn>
        <!-- <v-btn light small style="margin-right: 5px">
          <v-icon color="primary">mdi-folder-multiple-image</v-icon>
        </v-btn> -->
        <v-btn light small style="margin-right: 5px">
          <v-icon color="primary">mdi-note-outline</v-icon>
        </v-btn>
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="benestareallegatidialog = true"
              v-bind="attrs"
              v-on="on"
              light
              small
            >
              <v-icon color="primary">mdi-paperclip</v-icon>
            </v-btn>
          </template>
          <span>Allegati</span>
        </v-tooltip>
        <Button
          classe_bottone="ml-1"
          colore_icona="success"
          idForm="QUALITA"
          idOggetto="SALVA_PIANOCONTROLLO"
          mdi_icona="mdi-floppy"
          testo_tooltip="Salva"
          v-on:buttonClick="salvaPianoControllo"
        />
      </v-col>
      <v-spacer></v-spacer>
      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" @click="clickChiudi" small>
            <v-icon color="error">mdi-keyboard-backspace</v-icon>
          </v-btn>
        </template>
        <span>Torna al Paino Controllo</span>
      </v-tooltip>
    </v-toolbar>
    <div>
      <testa-commune-nuova
        :key="testaCommune.id_piano_controllo"
        v-on:update="getVal($event)"
        v-on:fmea_rev="getfmearev($event)"
        v-on:datagenerale="getdatagen($event)"
        v-on:articologenerale="getarticologen($event)"
        v-on:desgenerale="getdesgenerale($event)"
        v-on:codartgen="getcodartgen($event)"
        v-on:idarticolo="getidarticolo($event)"
        v-on:indicegenerale="getindicegenerale($event)"
        v-on:dumgen="getdumgen($event)"
        v-on:criticagen="getcriticagen($event)"
        v-on:fasiattgen="getfasiattgen($event)"
        v-on:fasesucc="getfasesucc($event)"
        v-on:macchinagen="getmacchinagen($event)"
        v-on:approvatogen="getapprovatogen($event)"
        v-on:sendragione="sendragione($event)"
        v-on:sendidFronitore="sendidFronitore($event)"
        :testaCommune="testaCommune"
      />
      <v-row style="margin-left: 12px; margin-right: 12px; margin-top: 5px">
        <v-data-table
          style="width: 100%"
          :headers="headers_piano_controllo"
          :items="item_piano_controllo"
          item-key="id_allegati"
          class="elevation-1"
          id="listener_piano"
        >
          <template v-slot:top>
            <v-toolbar color="indigo" dense>
              <v-toolbar-title class="white--text" color="indigo"
                >Elementi</v-toolbar-title
              >
              <v-spacer></v-spacer>
              <Button
                classe_bottone="ml-1"
                colore_icona="primary"
                idForm="QUALITA"
                idOggetto="CREA_PIANOCONTROLLO_ELEMENT"
                mdi_icona="mdi-plus"
                testo_tooltip="Aggiungi elemento"
                v-on:buttonClick="addRecord"
              />
              <v-spacer></v-spacer>
            </v-toolbar>
          </template>
          <template v-slot:[`item.detail`]="{ item }">
            <Button
              classe_bottone="ml-1"
              colore_icona="primary"
              idForm="QUALITA"
              idOggetto="MODIFICA_PIANOCONTROLLO_ELEMENT"
              mdi_icona="mdi-pencil"
              testo_tooltip="Dettaglio Elemento"
              v-on:buttonClick="editRecord(item)"
            />
          </template>
          <template v-slot:[`item.miniatura`]="{ item }">
            <div
              :id="10 + item.id_piano_controllo_dettaglio"
              style="
                background-repeat: no-repeat;
                background-size: 100% 100%;
                border: 1px solid black;
                height: 100px;
                margin-top: 5px;
                width: 100px;
              "
            ></div>
          </template>
          <template v-slot:[`item.delete`]="{ item }">
            <Button
              classe_bottone="ml-1"
              colore_icona="error"
              idForm="QUALITA"
              idOggetto="ELIMINA_PIANOCONTROLLO_ELEMENT"
              mdi_icona="mdi-window-close"
              testo_tooltip="Cancellare"
              v-on:buttonClick="deleteRecord(item)"
            />
          </template>
        </v-data-table>
      </v-row>
    </div>
  </div>
</template>
<script>
import TestaCommuneNuova from "../TestaCommuneNuova.vue";
import GestioneAllegato from "../../TOOLS/GestioneAllegato.vue";
import Controllo from "@/views/PRODUCTION/Controllo/Controllo.vue";
import Button from "@/components/TOOLS/FormComponentPermissions/Button.vue";
import TextField from "@/components/TOOLS/FormComponentPermissions/TextField.vue";
export default {
  name: "PianoControllo",
  components: {
    Controllo,
    Button,
    TestaCommuneNuova,
    GestioneAllegato,
    TextField,
    Modules: () => import("@/components/TOOLS/Modules"),
  },
  data() {
    return {
      rules: {
        // RULE in order to keep track of A VALUE
        min: (value) => {
          if (this.val_minimo != null) {
            if (this.val_minimo != null && value != null) {
              return parseInt(value) > parseInt(this.val_minimo);
            } else return false;
          } else return true;
        },
      },
      unita_misura_lista: [],
      // Snackbar vars
      controllo_qualita_visible: false,
      snackbar: false,
      snackbar_background: "",
      snackbar_icon: "",
      snackbar_icon_color: "",
      snackbar_text: "",
      snackbar_text_color: "",
      val_minimo: null,
      val_massimo: null,
      note: "",
      overlay: false,
      testaCommune: {},
      benestareallegatidialog: false,
      id_caratteristica: "",
      modifica: false,
      riga: null,
      guasto: "",
      // SelectedElement
      sel_elem: {},
      // Deleted Ciclo autocontrollo
      deletedbenes: [],
      // Lists for storing V-autocomplete Lists
      caratteristiche: [],
      // Strumenti lista
      tipocontrollo: [],
      // Crtitcita Lista
      difetto: [],
      unitamisura: [],
      // Guasto Lista
      modi_di_guasto: [],
      val_nominale: "",
      unita_misura: "",
      tipo_controllo: "",
      del_painoControllo: [],
      elementoDialog: false,
      dettaglio_object: {
        date_ins: null,
        date_mod: null,
        des_caratteristica: null,
        id_fmea_modo_guasto: null,
        id_fmea_fase_collaudo: null,
        id_caratteristica: null,
        id_tipo_controllo: null,
        id_difetto: null,
        id_um: null,
        lqa: null,
        id_utente: null,
        note: null,
        val_minimo: null,
        val_massimo: null,
        val_nominale: null,
        miniatura: -1,
        validita: 1,
        id_piano_controllo_dettaglio: -1,
        id_piano_controllo: -1,
      },
      main_info_object: {
        id_piano_controllo: -1,
        num_piano_controllo: null,
        data_piano: null,
        des_piano_controllo: null,
        stato: null,
        des_stato: null,
        id_famiglia: null,
        id_articolo: null,
        cod_articolo_esterno: null,
        cod_articolo: null,
        des_articolo: null,
        indice_modifica: null,
        dum: null,
        id_piano_campionamento: null,
        id_macchina: null,
        id_ciclo_attuale: null,
        id_ciclo_successivo: null,
        id_fase_attuale: null,
        id_fase_successivo: null,
        // id_difetto: null,
        id_firmatario: null,
        nominativo_firmatario: null,
        rientro_diretto: null,
        id_fmea: null,
        num_fmea: null,
        rev_fmea: null,
        id_fmea_fasi: null,
        id_fmea_fase_collaudo: null,
        num_revisione: null,
        id_anagrafica: null,
        cod_anagrafica: null,
        ragione_sociale: null,
        note: null,
        validita: null,
        date_ins: null,
        date_mod: null,
        id_utente: null,
        des_fase_attuale: null,
        des_fase_successivo: null,
      },
      headers_piano_controllo: [
        {
          text: "",
          value: "detail",
          sortable: false,
        },
        {
          text: "Tipo carat.",
          value: "des_caratteristica",
          align: "center",
          sortable: false,
        },
        {
          text: "Guasto",
          value: "des_guasto",
          align: "center",
          sortable: false,
        },
        {
          text: "Tipo controllo",
          value: "des_tipo",
          align: "center",
          sortable: false,
        },
        {
          text: "Difetto",
          value: "des_difetto",
          align: "center",
          sortable: false,
        },
        {
          text: "Unita misura",
          value: "des_um",
          align: "center",
          sortable: false,
        },
        {
          text: "Val min",
          value: "val_minimo",
          align: "center",
          sortable: false,
        },
        {
          text: "Val max",
          value: "val_massimo",
          align: "center",
          sortable: false,
        },
        {
          text: "Val nominale",
          value: "val_nominale",
          align: "center",
          sortable: false,
        },
        {
          text: "Miniatura",
          value: "miniatura",
          align: "center",
          sortable: false,
        },
        {
          text: "Note",
          value: "note",
          align: "center",
          sortable: false,
        },
        {
          text: "",
          value: "delete",
          sortable: false,
        },
      ],
      item_piano_controllo: [],
    };
  },
  methods: {
    aggiungiImg(messaggio) {
      this.elementoDialog = false;

      /* Recupero l'immagine nel messaggio
       * che è un base64 non splittato. */
      let photo = messaggio.detail.img;
      // Uso il base64 per valorizzare la src di un oggetto immagine
      let img = new Image();

      img.onload = function () {
        /* Ora ho un'immagine di cui posso conoscere altezza e larghezza,
         * e dimensiono il div in maniera da accoglierla senza stretcharla.
         * Voglio che le dimensioni dell'immagine non siano mai
         * superiori a 100 px; se lo sono scalo le dimesioni.*/
        if (img.width > img.height && img.width > 100) {
          let width_nuova = 100;
          // Proporzione -> width_nuova : img.width = height_nuova : img.height
          let height_nuova = (width_nuova * img.height) / img.width;
          document.getElementById(messaggio.detail.id).style.width = width_nuova + "px";
          document.getElementById(messaggio.detail.id).style.height = height_nuova + "px";
        } else if (img.height > img.width && img.height > 100) {
          let height_nuova = 100;
          // Proporzione -> width_nuova : img.width = height_nuova : img.height
          let width_nuova = (height_nuova * img.width) / img.height;
          document.getElementById(messaggio.detail.id).style.width = width_nuova + "px";
          document.getElementById(messaggio.detail.id).style.height = height_nuova + "px";
        } else {
          document.getElementById(messaggio.detail.id).style.width = img.width + "px";
          document.getElementById(messaggio.detail.id).style.height = img.height + "px";
        }
      };
      // Trasformo l'immagine del messaggio nello sfondo del mio div
      img.src = photo;
      document.getElementById(messaggio.detail.id).style.backgroundImage =
        "url('" + img.src + "')";
    },
    //funzione triggerata per cogliere l' errore degli allegati che non hanno la descrizione
    snackbarOpen() {
      //funzione triggerata per cogliere l' errore degli allegati che non hanno la descrizione inserita
      this.snackbar = true;
      this.snackbar_background = "orange";
      this.snackbar_icon = "mdi-alert";
      this.snackbar_icon_color = "white";
      this.snackbar_text = "Aggiungere ad ogni Allegato dell'attività una descrizione.";
      this.snackbar_text_color = "white";
    },
    deleteRecord(item) {
      if (item.id_piano_controllo > -1) {
        item.validita = 0;
        this.del_painoControllo.push(item);
        let i = this.item_piano_controllo.findIndex((it) => it == item);
        this.item_piano_controllo.splice(i, 1);
      } else {
        let i = this.item_piano_controllo.findIndex((it) => it == item);
        this.item_piano_controllo.splice(i, 1);
      }
    },
    // Saving or modifying one caratteristic
    salvacaratteristicha() {
      // this is logic to create new caratteristica
      if (this.dettaglio_object.id_piano_controllo > -1) {
        // for (let index = 0; index < this.item_piano_controllo.length; index++) {
        //   const element = this.item_piano_controllo[index];
        //   if (element.riga == this.riga) {
        //     if (this.sel_elem != element) {
        //       this.snackbar = true;
        //       this.snackbar_color = "error";
        //       this.snackbar_icon = "mdi-window-close";
        //       this.snackbar_text =
        //         "Non è possibile salvare due righe con lo stesso codice";
        //       return;
        //     }
        //   }
        // }
        // this.dettaglio_object.riga =
        //   this.riga == null ? this.dettaglio_object.riga : this.riga;
        this.dettaglio_object.val_nominale = this.val_nominale;
        this.dettaglio_object.val_minimo = this.val_minimo;
        this.dettaglio_object.val_massimo = this.val_massimo;
        this.dettaglio_object.note = this.note;
        // Here i am getting the values of ids in order to populate the table it will end with comment ending with number*
        if (this.dettaglio_object.id_caratteristica > -1) {
          this.caratteristiche.map((it) => {
            if (it.id_tabella == this.dettaglio_object.id_caratteristica) {
              this.dettaglio_object.des_caratteristica = it.des;
            }
          });
        }
        if (this.dettaglio_object.id_fmea_modo_guasto > -1) {
          this.modi_di_guasto.map((it) => {
            if (it.id_tabella == this.dettaglio_object.id_fmea_modo_guasto) {
              this.dettaglio_object.des_guasto = it.des;
            }
          });
        }
        if (this.dettaglio_object.id_tipo_controllo > -1) {
          this.tipocontrollo.map((it) => {
            if (it.id_tabella == this.dettaglio_object.id_tipo_controllo) {
              this.dettaglio_object.des_tipo = it.des;
            }
          });
        }
        if (this.dettaglio_object.id_difetto > -1) {
          this.difetto.map((it) => {
            if (it.id_tabella == this.dettaglio_object.id_difetto) {
              this.dettaglio_object.des_difetto = it.des;
            }
          });
        }
        if (this.dettaglio_object.id_um > -1) {
          this.unitamisura.map((it) => {
            if (it.id_tabella == this.dettaglio_object.id_um) {
              this.dettaglio_object.des_um = it.des;
            }
          });
        }
        //////////////////////**************************************/
        let i = this.item_piano_controllo.findIndex((it) => it == this.dettaglio_object);
        this.item_piano_controllo.splice(i, 1, this.dettaglio_object);
        this.elementoDialog = false;
      } else {
        // for (let index = 0; index < this.item_piano_controllo.length; index++) {
        //   const element = this.item_piano_controllo[index];
        //   if (element.riga == this.riga) {
        //     if (this.dettaglio_object != element) {
        //       this.snackbar = true;
        //       this.snackbar_color = "error";
        //       this.snackbar_icon = "mdi-window-close";
        //       this.snackbar_text =
        //         "Non è possibile salvare due righe con lo stesso codice";
        //       return;
        //     }
        //   }
        // }
        ////////////Here i am Creating a new Caratteristicha
        this.dettaglio_object.validita = 1;
        // this.dettaglio_object.riga = this.riga;
        this.dettaglio_object.val_nominale = this.val_nominale;
        this.dettaglio_object.val_minimo = this.val_minimo;
        this.dettaglio_object.val_massimo = this.val_massimo;
        this.dettaglio_object.note = this.note;
        if (this.dettaglio_object.id_caratteristica > -1) {
          this.caratteristiche.map((it) => {
            if (it.id_tabella == this.dettaglio_object.id_caratteristica) {
              this.dettaglio_object.des_caratteristica = it.des;
            }
          });
        }
        if (this.dettaglio_object.id_fmea_modo_guasto > -1) {
          this.modi_di_guasto.map((it) => {
            if (it.id_tabella == this.dettaglio_object.id_fmea_modo_guasto) {
              this.dettaglio_object.des_guasto = it.des;
            }
          });
        }
        if (this.dettaglio_object.id_tipo_controllo > -1) {
          this.tipocontrollo.map((it) => {
            if (it.id_tabella == this.dettaglio_object.id_tipo_controllo) {
              this.dettaglio_object.des_tipo = it.des;
            }
          });
        }
        if (this.dettaglio_object.id_difetto > -1) {
          this.difetto.map((it) => {
            if (it.id_tabella == this.dettaglio_object.id_difetto) {
              this.dettaglio_object.des_difetto = it.des;
            }
          });
        }
        if (this.dettaglio_object.id_um > -1) {
          this.unitamisura.map((it) => {
            if (it.id_tabella == this.dettaglio_object.id_um) {
              this.dettaglio_object.des_um = it.des;
            }
          });
        }
        this.dialog_gestione_record = false;
        const i = this.item_piano_controllo.findIndex(
          (it) => it == this.dettaglio_object
        );
        if (i > -1) {
          this.item_piano_controllo.splice(i, 1, this.dettaglio_object);
        }
        if (i == -1) {
          this.item_piano_controllo.push(this.dettaglio_object);
        }
      }
      this.elementoDialog = false;
    },
    // To get all list of v-autocomplete
    getTabelleAutocontrollo() {
      let request = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "gettabelle",
          token: localStorage.getItem("user_token"),
          tipo:
            "'CARATTERISTICHE','MODO_DI_GUASTO','TIPOCONTROLLO','DIFETTO','UNITA_MISURA'",
          filter: "validita = 1",
        },
      };
      this.$store.dispatch("api", request).then((res) => {
        this.caratteristiche = res.data.CARATTERISTICHE;
        this.modi_di_guasto = res.data.MODO_DI_GUASTO;
        this.tipocontrollo = res.data.TIPOCONTROLLO;
        this.difetto = res.data.DIFETTO;
        this.unitamisura = res.data.UNITA_MISURA;
      });
    },
    aggiungiElemento() {
      this.dettaglio_object.validita = 1;
      this.item_piano_controllo.push(this.dettaglio_object);
      this.elementoDialog = false;
    },
    editRecord(item) {
      this.elementoDialog = true;
      this.dettaglio_object = item;
      this.id_caratteristica = item.id_caratteristica;
      this.guasto = item.guasto;
      this.unita_misura = item.unita_misura;
      this.tipo_controllo = item.tipo_controllo;
      this.val_minimo = item.val_minimo;
      this.val_nominale = item.val_nominale;
      this.val_massimo = item.val_massimo;
      this.note = item.note;
    },
    addRecord() {
      this.elementoDialog = true;
      this.val_minimo = null;
      this.val_massimo = null;
      this.riga = null;
      this.modifica = false;
      this.note = null;
      this.val_nominale = null;
      this.dialog_gestione_record = true;
      this.dettaglio_object = {
        date_ins: null,
        date_mod: null,
        des_caratteristica: null,
        id_fmea_modo_guasto: null,
        id_fmea_fase_collaudo: null,
        id_caratteristica: null,
        id_tipo_controllo: null,
        id_difetto: null,
        id_um: null,
        lqa: null,
        id_utente: null,
        note: null,
        val_minimo: null,
        val_massimo: null,
        val_nominale: null,
        validita: 1,
        id_piano_controllo_dettaglio: -1,
        id_piano_controllo: -1,
      };
    },
    clickChiudi() {
      if (this.$route.params.id > 0) {
        this.$router.push({
          name: "Qualita",
          params: {
            comp: 1,
            id: this.testaCommune.id_articolo,
          },
        });
      } else {
        this.$router.push({
          name: "Qualita",
          params: { comp: 1 },
        });
      }
    },
    getVal(ev) {
      if (this.$route.params.id == -1) {
        this.main_info_object.numero_info = ev;
      }
      if (this.$route.params.id > 0) {
        this.testaCommune.numero_info = ev;
      }
    },
    getdesfaseattuale(ev) {
      if (this.$route.params.id == -1) {
        this.main_info_object.des_fase_attuale = ev;
      }
      if (this.$route.params.id > 0) {
        this.testaCommune.des_fase_attuale = ev;
      }
    },
    getdesfasesuccessive(ev) {
      if (this.$route.params.id == -1) {
        this.main_info_object.des_fase_successivo = ev;
      }
      if (this.$route.params.id > 0) {
        this.testaCommune.des_fase_successivo = ev;
      }
    },
    getfmearev(ev) {
      if (this.$route.params.id == -1) {
        this.main_info_object.fmea_rev = ev;
      }
      if (this.$route.params.id > 0) {
        this.testaCommune.fmea_rev = ev;
      }
    },
    getdatagen(ev) {
      if (this.$route.params.id == -1) {
        this.main_info_object.data_piano = ev;
      }
      if (this.$route.params.id > 0) {
        this.testaCommune.data_piano = ev;
      }
    },
    getarticologen(ev) {
      if (this.$route.params.id == -1) {
        this.main_info_object.cod_articolo = ev;
      }
      if (this.$route.params.id > 0) {
        this.testaCommune.cod_articolo = ev;
      }
    },
    getdesgenerale(ev) {
      if (this.$route.params.id == -1) {
        this.main_info_object.des_piano_controllo = ev;
      }
      if (this.$route.params.id > 0) {
        this.testaCommune.des_piano_controllo = ev;
      }
    },
    getcodartgen(ev) {
      if (this.$route.params.id == -1) {
        this.main_info_object.cod_articolo_esterno = ev;
      }
      if (this.$route.params.id > 0) {
        this.testaCommune.cod_articolo_esterno = ev;
      }
    },
    getidarticolo(ev) {
      if (this.$route.params.id == -1) {
        this.main_info_object.id_articolo = ev;
      }
      if (this.$route.params.id > 0) {
        this.testaCommune.id_articolo = ev;
      }
    },
    getindicegenerale(ev) {
      if (this.$route.params.id == -1) {
        this.main_info_object.indice_modifica = ev;
      }
      if (this.$route.params.id > 0) {
        this.testaCommune.indice_modifica = ev;
      }
    },
    getdumgen(ev) {
      if (this.$route.params.id == -1) {
        this.main_info_object.dum = ev;
      }
      if (this.$route.params.id > 0) {
        this.testaCommune.dum = ev;
      }
    },
    getcriticagen(ev) {
      if (this.$route.params.id == -1) {
        this.main_info_object.id_criticita = ev;
      }
      if (this.$route.params.id > 0) {
        this.testaCommune.id_criticita = ev;
      }
    },
    getfasiattgen(ev) {
      if (this.$route.params.id == -1) {
        this.main_info_object.id_fase_attuale = ev;
      }
      if (this.$route.params.id > 0) {
        this.testaCommune.id_fase_attuale = ev;
      }
    },
    getfasesucc(ev) {
      if (this.$route.params.id == -1) {
        this.main_info_object.id_fase_successivo = ev;
      }
      if (this.$route.params.id > 0) {
        this.testaCommune.id_fase_successivo = ev;
      }
    },
    getmacchinagen(ev) {
      if (this.$route.params.id == -1) {
        this.main_info_object.id_macchina = ev;
      }
      if (this.$route.params.id > 0) {
        this.testaCommune.id_macchina = ev;
      }
    },
    getapprovatogen(ev) {
      if (this.$route.params.id == -1) {
        this.main_info_object.nominativo_firmatario = ev;
      }
      if (this.$route.params.id > 0) {
        this.testaCommune.nominativo_firmatario = ev;
      }
    },
    sendragione(ev) {
      if (this.$route.params.id == -1) {
        this.main_info_object.ragione_sociale = ev;
      }
      if (this.$route.params.id > 0) {
        this.testaCommune.ragione_sociale = ev;
      }
    },
    sendidFronitore(ev) {
      if (this.$route.params.id == -1) {
        this.main_info_object.id_fornitore = ev;
      }
      if (this.$route.params.id > 0) {
        this.testaCommune.id_fornitore = ev;
      }
    },
    async salvaPianoControllo() {
      if (this.$refs.componenteAllegati != undefined) {
        if (!this.$refs.componenteAllegati.allegato_sel.des_allegato) {
          this.snackbar = true;
          this.snackbar_background = "orange";
          this.snackbar_icon = "mdi-alert";
          this.snackbar_icon_color = "white";
          this.snackbar_text = "È necessario des di allegato.";
          this.snackbar_text_color = "white";
          return;
        }
      }
      this.overlay = true;
      if (this.$route.params.id == -1) {
        this.overlay = true;
        this.main_info_object.validita = 1;
        this.main_info_object.qm_piano_controllo_dettaglio = this.item_piano_controllo;
        let setPianoControllo = {
          controller: "qualita",
          method: "PUT",
          richiesta: {
            action: "setpianocontrollo",
            token: localStorage.user_token,
            qm_piano_controllo: [this.main_info_object],
            id_piano_controllo: -1,
          },
        };
        await this.$store.dispatch("api", setPianoControllo).then(async (res) => {
          this.snackbar = true;
          this.overlay = false;
          if (res.status == 200 && this.$refs.componenteAllegati != undefined) {
            this.$refs.componenteAllegati.id_riferimento_creazione_genitore = res.data;
            this.$refs.componenteAllegati.salvaTuttiAllegati();
          }
          this.snackbar_text = "Modifica effettuata con successo!";
          this.color = "green darken-3";
          this.mainIcon = "mdi-check-circle";
          this.$router.push({
            name: "Qualita",
            params: {
              comp: 1,
              id: this.main_info_object.id_articolo,
            },
          });
        });
      }
      // This is logic for Updating A ciclo auto controllo
      if (this.$route.params.id > 0) {
        if (this.del_painoControllo && this.del_painoControllo.length > 0) {
          this.del_painoControllo.map((it) => {
            this.item_piano_controllo.push(it);
          });
        }
        this.testaCommune.id_articolo = this.id_articolo
        this.testaCommune.qm_piano_controllo_dettaglio = this.item_piano_controllo;
        let setBenestare = {
          controller: "qualita",
          method: "PUT",
          richiesta: {
            action: "setpianocontrollo",
            token: localStorage.user_token,
            qm_piano_controllo: [this.testaCommune],
            id_piano_controllo: this.testaCommune.id_piano_controllo,
          },
        };
        await this.$store.dispatch("api", setBenestare).then((res) => {
          if (res.status == 200 && this.$refs.componenteAllegati != undefined) {
            this.$refs.componenteAllegati.salvaTuttiAllegati();
          }
          this.overlay = false;
          this.snackbar = true;
          this.snackbar_text = "Modifica effettuata con successo!";
          this.color = "green darken-3";
          this.mainIcon = "mdi-check-circle";
          this.$router.push({
            name: "Qualita",
            params: {
              comp: 1,
              id: this.testaCommune.id_articolo,
            },
          });
        });
      }
    },
  },
  mounted() {
    document
      .getElementById("listener_piano")
      .addEventListener("nuova_img", this.aggiungiImg);
    this.getTabelleAutocontrollo();
    let routeParams = this.$route.params.id;
    if (routeParams > 0) {
      this.overlay = true;
      let getPianoControllo = {
        controller: "qualita",
        method: "POST",
        richiesta: {
          action: "getpianocontrollo",
          token: localStorage.user_token,
          id_piano_controllo: routeParams,
        },
      };
      this.$store.dispatch("api", getPianoControllo).then((res) => {
        this.overlay = false;
          this.id_articolo = res.data.qm_piano_controllo[0].id_articolo
        this.item_piano_controllo =
          res.data.qm_piano_controllo[0].qm_piano_controllo_dettaglio;
        this.testaCommune = res.data.qm_piano_controllo[0];
      });
    }
  },
  watch: {
    val_minimo: {
      immediate: true,
      async handler() {
        if (this.$refs.form != undefined) {
          await this.$nextTick();
          this.$refs.form.validate();
        }
      },
    },
    // here we are calculating thr val_nominale
    val_massimo: function () {
      const I = this.val_massimo;
      this[I] = !this[I];
    },
  },
};
</script>
